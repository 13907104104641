<template>
    <div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
        <div class="over_bgc">
            <!-- 顶部栏 -->
            <top-banner theme="white" @backHome="backHome"></top-banner>
            <div class="x_between">
                <!-- 左边 -->
                <div class="left" style="flex:1">
                    <!-- 标题 -->
                    <title-enzh enTitle="BIMOM" zhTitle="订阅服务" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
                    <div class="title">{{subject}}</div>
                    <div class="title">订单号：{{orderId}}</div>
                    <div class="title">订阅时长：{{month}} 个月</div>
                    <div class="title">订单金额：¥{{amount}}</div>
                    <div class="idea wrap_x_between">

                        <div style="width:80%">
<!--                            <div id="qrcode"></div>-->
                            <vue-qr :logoSrc="logo" :logoScale="20" :size="330" :text="wx_url" :margin="20" :dotScale="1"></vue-qr>
                            <div class="title">  </div>
                            <div class="title">请打开微信扫一扫支付订单，当前状态：{{result}}</div>
                            <div class="title">注：1、请在三十分钟内完成支付，否则订单将失效。</div>
                            <div class="title">    2、支付成功后请关闭BIMOM建筑插件重新登录，订阅即可生效。</div>
                        </div>
                    </div>

                </div>
                <!-- 右边 -->
<!--                <div class="right" style="flex:1">-->
<!--                    &lt;!&ndash; 标题 &ndash;&gt;-->
<!--                    &lt;!&ndash;                    <title-enzh enTitle="CONTACT US" zhTitle="联系我们" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>&ndash;&gt;-->
<!--                    &lt;!&ndash; 图片 &ndash;&gt;-->
<!--                    <img src="@/assets/image/home/sixPage/BIMOM.jpg" class="map_size">-->
<!--                    &lt;!&ndash; 公司信息 &ndash;&gt;-->
<!--                    <div class="company_info">-->
<!--                        <div class="address">成都公司：四川省成都市高新区天府五街</div>-->
<!--                        <div class="wrap_x_between">-->
<!--                            <div v-for="(item,index) in infoList" :key="index" class="info_item y_center">-->
<!--                                <img :src="item.icon" class="item_icon">-->
<!--                                <div v-text="item.name" class="item_name"></div>-->
<!--                            </div>-->
<!--                            <div class="empty_company_info"></div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="copyright">版权所有 成都悟空和展昭科技有限公司  丨 技术支持 成都悟空和展昭科技有限公司</div>-->
<!--                </div>-->

            </div>


        </div>
    </div>
</template>

<script>
    import vueQr from 'vue-qr'
    import topBanner from "@/components/topBanner.vue"
    import titleEnzh from "@/components/titleEnzh.vue"
    import {Encrypt} from '../assets/utils/secret.js'
    export default {
        components:{
            topBanner,
            titleEnzh,
            vueQr
        },
        data(){
            return {
                orderId:'',
                wx_url:"",
                subject:"11",
                amount:0,
                month:"1",
                logo:require('@/assets/image/wx.svg'),
                result:"未支付"
            }
        },
        mounted(){
            this.orderId=this.$route.query.orderId
            console.log(this.orderId)
            this.getCode();
        },
        methods: {

            handleRemove(file) {
                console.log(file);
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            backHome(){
                this.$router.push("/")
            },

            getCode(){
                if(this.disabled===true){
                    alert('获取太频繁，请等待');
                    return;
                }
                let param = {
                    orderId: this.orderId,
                };
                let config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };
                console.log(this.currentRow);
                this.axios.post(this.VUE_APP_BASE_API + 'wechat/pay', Encrypt(JSON.stringify(param)), config).then((res) => {
                    if (res.data.resultCode == 0) {

                        this.wx_url=res.data.code_url;
                        this.subject=res.data.order_subject;
                        this.amount=res.data.total_fee/100.0;
                        this.month=res.data.month;
                        console.log(this.wx_url);
                        this.loopOrderState();
                        // let qrcode = new QRCode('qrcode', {
                        //     width: 325,  // 宽
                        //     height: 325, // 高
                        //     text: this.wx_url, // 二维码内容,也可以直接放接口获取的数据
                        //     // render: 'canvas'     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                        //     // background: '#f0f'   // 自定义颜色默认黑白
                        //     // foreground: '#ff0'
                        // })
                    } else {
                        alert("获取失败:  "+ res.data.resultData);
                    }
                });
            },
            loopOrderState() {
                let param = {
                    orderId:this.orderId,
                };
                let config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };
                this.T = setInterval(() => {
                    this.axios.post(this.VUE_APP_BASE_API + 'orders/getstatus',Encrypt(JSON.stringify(param)), config).then((res) => {
                        console.log(res.data.resultCode);
                        if (res.data.resultCode == 1) {//10是未支付  20已付款
                            clearInterval(this.T)
                            // this.goOrderList()
                            this.result="已支付";
                        }
                    })
                }, 1000)
            }
        }
    }
</script>

<style scoped>
    .idea >>> .el-upload-list--picture-card .el-upload-list__item{
        width: 120px;
        height: 120px;
    }
    .idea >>> .el-upload--picture-card{
        width: 120px;
        height: 120px;
        background-color: transparent;
        border: none;
    }
    .idea >>> .el-radio__label{
        font-size: 18px;
        color: #fff;
    }
</style>
<style lang="scss" scoped>
    .empty{
        min-width: 110px;
        height: 0px;
    }
    .empty_company_info{
        width: 250px;
        height: 0;
    }
    .bgm{
        width: 100%;
        height: 100vh;
        background: url("../assets/image/feedback/feedback_bgm.jpg") no-repeat;
        background-size: cover;
        overflow: hidden;
        .over_bgc{
            width: 100%;
            min-height: 100vh;
            background: rgba($color: #000000, $alpha: .7);
            padding: 50px 70px !important;
        }
    }
    .left{
        //padding-right:1000px;
        color: #D7D7D7;
        font-size: 19px;
        .idea{
            margin: 10px 0;
            padding: 0px;
            border-radius: 6px;
            border: 0px solid rgba(121, 121, 121, 1);
            width:1500px;
            .title{
                color: #D7D7D7;
                text-align: left;
                font-size: 20px;
            }
            .radio_item{
                min-width: 110px;
                margin-top: 20px;
            }
        }
    }
    .right{
        padding-left: 60px;
        .map_size{
            width: 100%;
            height: 470px;
            margin: 30px 0;
            border-radius: 10px;
        }
        .company_info{
            font-family: '幼圆 Bold', '幼圆', sans-serif;
            font-size: 18px;
            color: #797979;
            margin-bottom: 20px;
            .address{
                margin-bottom:30px;
                font-weight: bold;
            }
            .info_item{
                font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
                margin-bottom:26px;
                width: 250px;
                .item_icon{
                    width:20px;
                    margin-right:20px
                }
                .item_name{
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
        .copyright{
            font-family: '幼圆 Bold', '幼圆', sans-serif;
            font-size: 16px;
            color: #797979;
            font-weight: bold;
        }
    }


    // 输入框
    textarea{
        background: rgba($color: #fff, $alpha: .5);
        border-radius: 6px;
        color: #fff;
        font-size: 16px;
        outline:none;
        padding: 20px;
        margin-top: 10px;
    }
    .submit{
        width: 100%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
        font-size: 18px;
        color: #797979;
        border: 1px solid #797979;
        letter-spacing: 4px;
        border-radius: 20px;
        transform: translateX(-50%);
        position: relative;
        cursor: pointer;
        left: 50%;
        margin-top: 20px;
        &:hover{
            color: #fff;
            border: 1px solid #fff;
        }
    }
    .submit1{
        width: 140px;
        text-align: left;
        height: 45px;
        line-height: 45px;
        font-family: '幼圆', sans-serif;
        /*font-family: 'Agency FB Normal', 'Agency FB', sans-serif;*/
        font-size: 18px;
        color: #797979;
        border: 1px solid #797979;
        letter-spacing: 4px;
        border-radius: 10px;
        transform: translateX(-50%);
        position: relative;
        left: 50%;
        margin-top: 20px;
        &:hover{
            color: #fff;
            border: 1px solid #fff;
        }
    }
</style>